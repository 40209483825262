.userProfile .main-body{
    background: #fff7f7;;
    margin-top: 20px;
    padding : 20px;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid transparent;
    border-radius: .25rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);
}
.me-2 {
    margin-right: .5rem!important;
}

.list-group-item {
    overflow-wrap: anywhere;
}

.col-lg-8 .card-body{
    padding: 32px;;
}